import React, { useState } from "react"
import { Link } from "gatsby"
import styles from "./banner.module.scss"
import Container from "../../components/container/container"
import MailIcon from "../../images/navbar/mail.svg"
import PhoneIcon from "../../images/navbar/phone.svg"
import BurgerIcon from "../../images/navbar/burger.svg"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Banner = props => {
  const [state, setState] = useState({})
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => (window.location.href = form.getAttribute("action")))
      .catch(error => alert(error))
  }
  return (
    <div>
      <div className={`${styles.banner} ${styles[props.bgClass]}`}>
        <Container className={styles.banner__container}>
          <div className={styles.banner__navbar}>
            <Link to="/">
              <img
                src={props.logo}
                data-sal="slide-right"
                data-sal-duration="1000"
                className={styles.banner__navbar__logo}
              />
            </Link>
            <div
              className={styles.banner__navbar__icons}
              data-sal="slide-up"
              data-sal-duration="1000">
              <img src={PhoneIcon} alt="Phone" />
              <a href={`mailto:` + props.email}>
                <img src={MailIcon} alt="Mail" />
              </a>
              <img src={BurgerIcon} alt="Menu" onClick={props.openNav} />
            </div>
          </div>
          <div className={styles.banner__content}>
            <div className={styles.banner__content__half}>
              <h1>Get in touch</h1>
              <p>
                Feel free to contact us with any enquires. Use the form or give
                us call to discuss your project.
              </p>
              <p>
                <strong>One creative</strong>
              </p>
              <p>15 Seaton Rd, Thorpe Astley,</p>
              <p>Leicester, LE3 3SU</p>
              <a href="tel:01163183771">+44 (0) 116 3183771</a>
              <a href={`mailto:${props.email}`}>{props.email}</a>
            </div>
            <div className={styles.banner__content__half}>
              <form
                className={styles.banner__content__form}
                name="contact"
                action="/thanks/"
                method="POST"
                netlify
                onSubmit={handleSubmit}>
                <div className={styles.banner__content__form__half}>
                  <input
                    type="text"
                    placeholder="First Name *"
                    onChange={handleChange}
                    id="firstname"
                    name="firstname"
                  />
                </div>
                <div className={styles.banner__content__form__half}>
                  <input
                    type="text"
                    placeholder="Last Name"
                    onChange={handleChange}
                    id="lastname"
                    name="lastname"
                  />
                </div>
                <div className={styles.banner__content__form__half}>
                  <input
                    type="email"
                    placeholder="Email Address *"
                    onChange={handleChange}
                    id="email"
                    name="email"
                  />
                </div>
                <div className={styles.banner__content__form__half}>
                  <input
                    type="text"
                    placeholder="Contact Number"
                    onChange={handleChange}
                    id="phone"
                    name="phone"
                  />
                </div>
                <div className={styles.banner__content__form__full}>
                  <textarea
                    placeholder="Message"
                    onChange={handleChange}
                    id="message"
                    name="message"></textarea>
                </div>
                <div className={styles.banner__content__form__full}>
                  <input
                    type="submit"
                    className={styles.banner__content__form__submit}
                    value="Send Message"
                  />
                </div>
              </form>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Banner
