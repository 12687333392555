import React, { useState } from "react"
import { graphql } from "gatsby"
import Banner from "../components/contact/banner"
import Footer from "../components/footer/footer"
import Nav from "../components/navigation/navigation"
import SEO from "../components/seo"
import Testimonial from "../components/testimonial/testimonial"

const ContactPage = ({ data }) => {
  const [showNav, setShowNav] = useState(false)
  const bgClass = "contact"
  const bgClassTestimonials = "caseStudies"

  return (
    <div>
      <SEO title="Contact Us" />
      <Banner
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}
        title="Contact Us"
        bgClass={bgClass}
        openNav={() => setShowNav(true)}></Banner>
      {showNav ? (
        <Nav
          logo={data.strapiGlobal.logo.publicURL}
          close={() => setShowNav(false)}></Nav>
      ) : null}
      <Testimonial bgClass={bgClassTestimonials}></Testimonial>
      <Footer
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}></Footer>
    </div>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactQuery {
    strapiGlobal {
      email
      logo {
        publicURL
      }
    }
  }
`
